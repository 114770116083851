import React, { useState, useRef, useEffect } from "react";
import {
  Button,
  FormGroup,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import { getAuth } from "firebase/auth";
import moment from "moment";

function ReviewModal({ isOpen, toggle, onAdd }) {
  const [name, setName] = useState("");
  const [date, setDate] = useState(moment().format("YYYY-MM-DD HH:mm"));
  const userName = getAuth().currentUser.displayName;
  const inputRef = useRef(null);
  const handleOpen = () => inputRef.current.focus();

  useEffect(() => {
    if (userName) {
      setName(userName);
    }
  }, [userName]);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      if (name) {
        onAdd(name, date);
      } else {
        alert("Bitte Name eingeben!");
      }
    }
  };

  const onAddHandler = () => {
    onAdd(name, date);
    toggle();
  };

  return (
    <Modal fade={false} isOpen={isOpen} toggle={toggle} onOpened={handleOpen}>
      <ModalHeader toggle={toggle}>Neue Überprüfung hinzufügen</ModalHeader>
      <ModalBody>
        <FormGroup>
          <Input
            required
            type="text"
            placeholder="Bitte Name eingeben"
            value={name}
            onChange={(e) => setName(e.target.value)}
            onKeyDown={handleKeyDown}
            innerRef={inputRef}
          />
        </FormGroup>
        <FormGroup>
          <Input
            type="datetime-local"
            value={date}
            onChange={(e) => setDate(e.target.value)}
          />
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button disabled={!name} color="primary" onClick={onAddHandler}>
          Hinzufügen
        </Button>
        <Button color="secondary" onClick={toggle}>
          Abbrechen
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default ReviewModal;
