import React, { useEffect, useState, useCallback } from "react";
import { Button, Col, FormGroup, Input, Row, Table } from "reactstrap";
import { ImBin2 } from "react-icons/im";
import ConfirmModal from "./ConfirmModal";
import { storage } from "../../config/firebase";
import {
  getMetadata,
  listAll,
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";

function FormModalUploads({ driverID }) {
  const [files, setFiles] = useState([]);
  const [showFields, setShowFields] = useState(false);
  const [fileUpload, setFileUpload] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [fileName, setFileName] = useState("");

  const getFileList = useCallback(async () => {
    if (!driverID) return;
    const filesFolderRef = ref(storage, `driver-documents/${driverID}`);
    const listResult = await listAll(filesFolderRef);
    const fileListPromises = listResult.items.map(async (item) => {
      const metadata = await getMetadata(item);
      const downloadURLOptions = {
        expires: Date.now() + 300 * 1000,
      };
      const downloadURL = await getDownloadURL(item, downloadURLOptions);
      return {
        name: item.name,
        uploadDate: metadata.timeCreated,
        type: metadata.contentType,
        downloadURL: downloadURL,
      };
    });
    const fileList = await Promise.all(fileListPromises);
    setFiles(fileList);
  }, [driverID]);

  const uploadFile = async () => {
    if (!fileUpload) return;
    setIsUploading(true);
    const filesFolderRef = ref(
      storage,
      `driver-documents/${driverID}/${fileUpload.name}`
    );
    try {
      await uploadBytes(filesFolderRef, fileUpload);
      setIsUploading(false);
      setFileUpload(null);
      setShowFields(false);
      getFileList();
    } catch (error) {
      console.error(error);
    }
  };

  const deleteFile = async (name) => {
    const fileRef = ref(storage, `driver-documents/${driverID}/${name}`);
    try {
      await deleteObject(fileRef);
      getFileList();
      setShowConfirmModal(false);
    } catch (error) {
      console.error(error);
    }
  };

  const fileDeleteHandler = (name) => {
    setShowConfirmModal(true);
    setFileName(name);
  };

  useEffect(() => {
    getFileList();
  }, [getFileList]);

  if (!driverID) {
    return null;
  }

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h5>Uploads</h5>
        <Button onClick={() => setShowFields(!showFields)} size="sm">
          +
        </Button>
      </div>
      {showFields && (
        <Row>
          <Col md="8">
            <FormGroup>
              <Input
                type="file"
                onChange={(e) => setFileUpload(e.target.files[0])}
              />
            </FormGroup>
          </Col>
          <Col md="3">
            <Button disabled={!fileUpload} color="primary" onClick={uploadFile}>
              {isUploading ? "Datei wird gespeichert..." : "Datei speichern"}
            </Button>
          </Col>
        </Row>
      )}
      <Row>
        <Col md="12">
          <Table>
            <thead>
              <tr>
                <th>Dateiname</th>
                <th>Format</th>
                <th>Upload Datum</th>
                <th>Download</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {files.map((file, index) => (
                <tr key={index}>
                  <td>{file.name}</td>
                  <td>{file.type}</td>
                  <td>{new Date(file.uploadDate).toLocaleDateString()}</td>
                  <td>
                    <a href={file.downloadURL} target="_blank" rel="noreferrer">
                      Herunterladen
                    </a>
                  </td>
                  <td>
                    <ImBin2
                      onClick={() => fileDeleteHandler(file.name)}
                      style={{
                        cursor: "pointer",
                        fontSize: "1rem",
                      }}
                      color="#dc3545"
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>

      <ConfirmModal
        isOpen={showConfirmModal}
        text="Soll diese Datei wirklich gelöscht werden?"
        confirm={() => deleteFile(fileName)}
        close={() => setShowConfirmModal(false)}
      />
    </>
  );
}

export default FormModalUploads;
