import React, { useState, useEffect } from "react";
import Header from "./components/Header";
import Content from "./components/Content";
import Footer from "./components/Footer";
import LoginForm from "./components/LoginForm";

import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import "bootstrap/dist/css/bootstrap.min.css";

const App = () => {
  const [user, setUser] = useState(null);
  const auth = getAuth();
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      setIsInitialized(true);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      const parsedUser = JSON.parse(storedUser);
      setUser(parsedUser);
    }
  }, []);

  const loginHandler = (user) => {
    setUser(user);
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      setUser(null);
    } catch (error) {
      console.error("Logout fehlgeschlagen", error);
    }
  };

  return (
    <div className="app-container">
      <Header />
      {isInitialized ? (
        user ? (
          <Content user={user} handleLogout={handleLogout} />
        ) : (
          <LoginForm onLogin={loginHandler} />
        )
      ) : (
        <div className="loading" />
      )}
      <Footer />
    </div>
  );
};

export default App;
