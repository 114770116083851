import React, { useState } from "react";
import { Button, Col, FormGroup, Input, Row, Table } from "reactstrap";
import { formatDateString } from "../../utils/helper";

function FormModalInstructions({ person, addVehicle, removeVehicle }) {
  const [showFields, setShowFields] = useState(false);
  const [newVehicle, setNewVehicle] = useState("");
  const [instructionDate, setInstructionDate] = useState("");
  const [instructorName, setInstructorName] = useState("");
  const vehicleOptions = ["DEKON P", "DLK", "ELW", "HLF", "MTF", "MZF", "TLF"];

  const onAddVehicle = () => {
    setNewVehicle("");
    setInstructionDate("");
    setInstructorName("");
    addVehicle(newVehicle, instructionDate, instructorName);
  };

  const onKeyDownHandler = (e) => {
    e.preventDefault();
    onAddVehicle();
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h5>Einweisungfahrten</h5>
        <Button onClick={() => setShowFields(!showFields)} size="sm">
          +
        </Button>
      </div>
      {showFields && (
        <>
          <Row>
            <Col md="4">
              <FormGroup>
                <Input
                  type="select"
                  name="newVehicle"
                  id="newVehicle"
                  value={newVehicle}
                  onChange={(e) => setNewVehicle(e.target.value)}
                >
                  <option value="">Fahrzeug auswählen</option>
                  {vehicleOptions.map((vehicle, index) => (
                    <option key={index} value={vehicle}>
                      {vehicle}
                    </option>
                  ))}
                </Input>
              </FormGroup>
            </Col>
            <Col md="4">
              <FormGroup>
                <Input
                  type="date"
                  name="instructionDate"
                  id="instructionDate"
                  value={instructionDate}
                  onChange={(e) => setInstructionDate(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col md="4">
              <FormGroup>
                <Input
                  type="text"
                  name="instructorName"
                  id="instructorName"
                  placeholder="Name des Einweisers"
                  value={instructorName}
                  onChange={(e) => setInstructorName(e.target.value)}
                  onKeyDown={(e) => e.key === "Enter" && onKeyDownHandler(e)}
                />
              </FormGroup>
            </Col>
          </Row>
          <Button color="primary" onClick={onAddVehicle}>
            Fahrzeug hinzufügen
          </Button>
          <hr />
        </>
      )}
      {person.vehicles.length > 0 && (
        <Table>
          <thead>
            <tr>
              <th>Fahrzeug</th>
              <th>Eingewiesen am</th>
              <th>Eingewiesen von</th>
              <th>Löschen</th>
            </tr>
          </thead>
          <tbody>
            {person.vehicles.map((vehicle, index) => (
              <tr key={index}>
                <td>{vehicle.vehicle}</td>
                <td>{formatDateString(vehicle.date)}</td>
                <td>{vehicle.instructor}</td>
                <td>
                  <Button
                    color="danger"
                    size="sm"
                    onClick={() => removeVehicle(index)}
                  >
                    X
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </>
  );
}

export default FormModalInstructions;
