import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const ConfirmModal = ({
  isOpen,
  text = "Soll dieser Inhalt wirklich gelöscht werden?",
  confirm,
  close,
}) => {
  return (
    <Modal fade={false} isOpen={isOpen}>
      <ModalHeader>Löschen?</ModalHeader>
      <ModalBody>
        <p>{text}</p>
        <p>Diese Aktion kann nicht rückgängig gemacht werden.</p>
      </ModalBody>
      <ModalFooter>
        <Button onClick={confirm} color="danger">
          Delete
        </Button>
        <Button onClick={close} color="secondary">
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmModal;
