export const formatDateString = (dateString) => {
  const [year, month, day] = dateString.split("-");

  const date = new Date(year, month - 1, day);

  const formattedDay = String(date.getDate()).padStart(2, "0");
  const formattedMonth = String(date.getMonth() + 1).padStart(2, "0");
  const formattedYear = date.getFullYear();

  return `${formattedDay}.${formattedMonth}.${formattedYear}`;
};

export const timestampFromFirebaseToDateString = (timestamp) => {
  const milliseconds =
    timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000;
  const date = new Date(milliseconds);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");

  return `${year}-${month}-${day}`;
};

export const formatTimeStampToDate = (timestamp) => {
  const date = new Date(timestamp);
  const formattedDay = String(date.getDate()).padStart(2, "0");
  const formattedMonth = String(date.getMonth() + 1).padStart(2, "0");
  const formattedYear = date.getFullYear();
  return `${formattedDay}.${formattedMonth}.${formattedYear}`;
};

export const formatTimeStampToDateAndTime = (timestamp) => {
  const date = new Date(timestamp);
  const formattedDay = String(date.getDate()).padStart(2, "0");
  const formattedMonth = String(date.getMonth() + 1).padStart(2, "0");
  const formattedYear = date.getFullYear();
  const formattedHour = String(date.getHours()).padStart(2, "0");
  const formattedMinute = String(date.getMinutes()).padStart(2, "0");
  return `${formattedDay}.${formattedMonth}.${formattedYear} ${formattedHour}:${formattedMinute}`;
};

export const isTimestampMoreThanMonthsAgo = (timestamp, months) => {
  const currentDate = new Date();
  const targetDate = new Date(timestamp);

  const difference =
    (currentDate.getFullYear() - targetDate.getFullYear()) * 12 +
    (currentDate.getMonth() - targetDate.getMonth());

  return difference >= months;
};
