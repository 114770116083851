import React, { useEffect, useState } from "react";
import { Row, Label, FormGroup, Input, Col } from "reactstrap";
import { ImBin2 } from "react-icons/im";
import ConfirmModal from "./ConfirmModal";

const InputField = ({
  col = "6",
  label,
  id,
  value,
  onChange,
  required = true,
  type = "text",
}) => {
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    onChange(name, value);
  };

  return (
    <Col md={col}>
      <FormGroup>
        <Label for={id}>{label}</Label>
        <Input
          required={required}
          type={type}
          name={id}
          id={id}
          placeholder={label}
          value={value}
          onChange={handleInputChange}
        />
      </FormGroup>
    </Col>
  );
};

const FormModalInputs = ({
  person,
  onRemove,
  onChangeInput,
  onChangeClass,
}) => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [medicalCheckupRequired, setMedicalCheckupRequired] = useState(false);

  useEffect(() => {
    if (person.classes.includes("C1") || person.classes.includes("C")) {
      setMedicalCheckupRequired(true);
    } else {
      setMedicalCheckupRequired(false);
    }
  }, [person.classes]);

  return (
    <>
      <div className="d-flex justify-content-between">
        <h5>Persönliche Angaben</h5>
        <ImBin2
          onClick={() => setShowConfirmModal(true)}
          style={{ cursor: "pointer", fontSize: "1rem" }}
          color="#dc3545"
        />
      </div>
      <Row>
        <InputField
          label="Nachname"
          id="lastname"
          value={person.lastname}
          onChange={(name, value) => onChangeInput(name, value)}
        />

        <InputField
          label="Vorname"
          id="firstname"
          value={person.firstname}
          onChange={(name, value) => onChangeInput(name, value)}
        />
      </Row>
      <Row>
        <InputField
          label="Straße und Hausnummer"
          id="street"
          value={person.street}
          onChange={(name, value) => onChangeInput(name, value)}
        />
        <InputField
          label="PLZ und Wohnort"
          id="city"
          value={person.city}
          onChange={(name, value) => onChangeInput(name, value)}
        />
      </Row>
      <hr />
      <h5>Angaben zum Führerschein</h5>
      <Row>
        <InputField
          type="date"
          label="Gültig ab"
          id="validFrom"
          value={person.validFrom}
          onChange={(name, value) => onChangeInput(name, value)}
        />
        <InputField
          required={false}
          type="date"
          label="Gültig bis"
          id="validUntil"
          value={person.validUntil}
          onChange={(name, value) => onChangeInput(name, value)}
        />
      </Row>
      <Row>
        <InputField
          label="Führerscheinnummer"
          id="licenceNumber"
          value={person.licenceNumber}
          onChange={(name, value) => onChangeInput(name, value)}
        />
        <InputField
          label="Ausstellende Behörde"
          id="issuingAuthority"
          value={person.issuingAuthority}
          onChange={(name, value) => onChangeInput(name, value)}
        />
      </Row>
      <Row>
        <Col md="12">
          <FormGroup>
            <Label>Führerscheinklassen</Label>
            <div>
              {["B", "BE", "C1", "C1E", "C", "CE", "FW-Führerschein"].map(
                (classValue) => (
                  <FormGroup check inline key={classValue}>
                    <Label check>
                      <Input
                        type="checkbox"
                        name="classes"
                        value={classValue}
                        checked={person.classes.includes(classValue) || false}
                        onChange={() => onChangeClass(classValue)}
                      />
                      {classValue}
                    </Label>
                  </FormGroup>
                )
              )}
            </div>
          </FormGroup>
        </Col>
      </Row>

      {medicalCheckupRequired && (
        <Row>
          <InputField
            col="12"
            required={medicalCheckupRequired}
            label="Nächste medizinische Untersuchung (Klasse C1/C)"
            id="medicalCheckup"
            type="date"
            value={person.medicalCheckup}
            onChange={(name, value) => onChangeInput(name, value)}
          />
        </Row>
      )}
      <Row>
        <InputField
          col="12"
          required={false}
          label="Beschränkungen (Schlüsselzahl)"
          id="restrictions"
          value={person.restrictions}
          onChange={(name, value) => onChangeInput(name, value)}
        />
      </Row>
      <hr />
      {showConfirmModal && (
        <ConfirmModal
          isOpen={showConfirmModal}
          text={`${person.firstname} ${person.lastname} wirklich löschen?`}
          close={() => setShowConfirmModal(false)}
          confirm={() => onRemove(person.id)}
        />
      )}
    </>
  );
};

export default FormModalInputs;
