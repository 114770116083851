import React, { useState } from "react";
import { Button, Form, FormGroup, Label, Input } from "reactstrap";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../config/firebase";

const LoginForm = ({ onLogin }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const signInWithEmailAndPasswordHandler = async (e) => {
    e.preventDefault();
    try {
      const user = await signInWithEmailAndPassword(auth, email, password);
      onLogin(user);
    } catch (error) {
      setError("Fehler beim Anmelden. Bitte überprüfe deine Eingaben.");
    }
  };

  return (
    <div className="loginForm">
      <Form
        style={{
          width: "80%",
          maxWidth: "400px",
          margin: "0 auto",
          padding: "40px",
          border: "1px solid #ccc",
          borderRadius: "10px",
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
        }}
        onSubmit={signInWithEmailAndPasswordHandler}
      >
        <h2 style={{ textAlign: "center", marginBottom: "20px" }}>Login</h2>
        {error && (
          <p style={{ color: "red", textAlign: "center" }}>{error}</p>
        )}{" "}
        {/* Fehlermeldung anzeigen */}
        <FormGroup>
          <Label for="email">E-Mail</Label>
          <Input
            type="email"
            name="email"
            id="email"
            placeholder="E-Mail eingeben"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </FormGroup>
        <FormGroup>
          <Label for="password">Passwort</Label>
          <Input
            type="password"
            name="password"
            id="password"
            placeholder="Passwort eingeben"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </FormGroup>
        <Button color="danger" block>
          Anmelden
        </Button>
      </Form>
    </div>
  );
};

export default LoginForm;
