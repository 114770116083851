import React, { useEffect, useMemo, useState } from "react";
import { Button, Col } from "reactstrap";
import { db } from "../config/firebase";
import { FaInfoCircle, FaPlus, FaUserEdit } from "react-icons/fa";

import {
  collection,
  addDoc,
  getDocs,
  deleteDoc,
  doc,
  updateDoc,
} from "firebase/firestore";

import FormModal from "./modals/FormModal";
import LicenceTable from "./LicenceTable";
import UserModal from "./modals/UserModal";
import InfoModal from "./modals/InfoModal";

const Content = ({ user, handleLogout }) => {
  const [data, setData] = useState([]);
  const [showUserModal, setShowUserModal] = useState(false);
  const [showFormModal, setShowFormModal] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [selectedPerson, setSelectedPerson] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [fastCheck, setFastCheck] = useState(false);

  const driversRef = useMemo(() => collection(db, "drivers"), []);

  useEffect(() => {
    const getDrivers = async () => {
      setIsLoading(true);
      try {
        const data = await getDocs(driversRef);
        const drivers = data.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        setData(drivers);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
      }
    };
    getDrivers();
  }, [driversRef]);

  const addDriver = async (person) => {
    try {
      await addDoc(driversRef, person);
      window.location.reload();
    } catch (error) {
      console.error(error);
    }
  };

  const updateDriver = async (id, driver) => {
    try {
      const driverRef = doc(db, "drivers", id);
      const updatedDriver = { ...driver };
      delete updatedDriver.id;
      await updateDoc(driverRef, updatedDriver);
    } catch (error) {
      console.error(error);
    }
  };

  const deleteDriver = async (id) => {
    try {
      const driver = doc(db, "drivers", id);
      await deleteDoc(driver);
    } catch (error) {
      console.error(error);
    }
  };

  const clickTableRowHandler = (item, fastCheck) => {
    setSelectedPerson(item);
    setShowFormModal(true);

    if (fastCheck) {
      setFastCheck(true);
    }
  };

  const onSaveHandler = (person) => {
    const personexists = data.find((p) => p.id === person.id);

    if (personexists) {
      const index = data.findIndex((p) => p.id === person.id);
      const newData = [...data];
      newData[index] = {
        ...person,
      };
      setData(newData);
      updateDriver(person.id, person);
    }

    if (!personexists) {
      addDriver(person);
    }

    setSelectedPerson(null);
    setShowFormModal(false);
    setFastCheck(false);
  };

  const onResetHandler = () => {
    setSelectedPerson(null);
    setFastCheck(false);
    setShowFormModal(false);
  };

  const onRemoveHandler = (id) => {
    const updatedData = data.filter((item) => item.id !== id);
    setData(updatedData);
    deleteDriver(id);
    onResetHandler();
  };

  return (
    <div className="content">
      {isLoading ? (
        <div className="loading" />
      ) : (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: "20px",
            }}
          >
            <div style={{ display: "flex", gap: "10px" }}>
              <Button color="success" onClick={() => setShowFormModal(true)}>
                <FaPlus />
              </Button>
              <Button onClick={() => setShowInfoModal(!showInfoModal)}>
                <FaInfoCircle />
              </Button>
              {user && (
                <>
                  <Button
                    color="warning"
                    onClick={() => setShowUserModal(!showUserModal)}
                  >
                    <FaUserEdit />
                  </Button>
                  <Button color="danger" onClick={handleLogout}>
                    Logout
                  </Button>
                </>
              )}
            </div>
          </div>
          <Col xs="12">
            {data?.length > 0 ? (
              <LicenceTable data={data} clickTableRow={clickTableRowHandler} />
            ) : (
              <p className="text-center font-weight-bold">
                Keine Daten vorhanden
              </p>
            )}
          </Col>
          {showFormModal && (
            <FormModal
              show={showFormModal}
              selectedPerson={selectedPerson}
              save={onSaveHandler}
              remove={onRemoveHandler}
              reset={onResetHandler}
              fastCheck={fastCheck}
              cancelFastCheck={() => setFastCheck(false)}
            />
          )}
          {showUserModal && (
            <UserModal
              isOpen={showUserModal}
              toggle={() => setShowUserModal(!showUserModal)}
            />
          )}
        </>
      )}
      {showInfoModal && (
        <InfoModal
          isOpen={showInfoModal}
          toggle={() => setShowInfoModal(!showInfoModal)}
        />
      )}
    </div>
  );
};

export default Content;
