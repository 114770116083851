import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Input,
  Label,
  Alert,
} from "reactstrap";
import {
  getAuth,
  updateProfile,
  updatePassword,
  reauthenticateWithCredential,
  EmailAuthProvider,
} from "firebase/auth";

function UserModal({ isOpen, toggle }) {
  const [username, setUsername] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const currentUser = getAuth().currentUser;

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        toggle();
      }, 2000);
    }
  }, [success, toggle]);
  const changeName = async () => {
    try {
      await updateProfile(currentUser, { displayName: username });
      setSuccess("Der Name wurde erfolgreich geändert.");
    } catch (error) {
      setError(error.message);
      console.error("Fehler beim Aktualisieren des Displaynamens:", error);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSave();
    }
  };

  const changePassword = async () => {
    try {
      if (newPassword && confirmPassword === "") {
        setError("Bitte das neue Passwort erneut eingeben.");
        return;
      }

      if (newPassword !== confirmPassword) {
        setError("Die angegebenen Passwörter stimmen nicht überein.");
        return;
      }

      if (newPassword.length < 8) {
        setError("Das Passwort muss mindestens 8 Zeichen lang sein.");
        return;
      }

      if (currentPassword === "") {
        setError("Bitte das alte Passwort eingeben.");
        return;
      }

      try {
        const credential = reauthenticateWithCredential(
          currentUser,
          EmailAuthProvider.credential(currentUser.email, currentPassword)
        );
        await credential;
      } catch (error) {
        setError("Das eingegebene Passwort ist falsch.");
        console.error(error);
      }

      await updatePassword(currentUser, newPassword);
      setSuccess("Das Passwort wurde erfolgreich geändert.");
    } catch (error) {
      setError(error.message);
      console.error("Fehler beim ändern des Passworts:", error);
    }
  };

  const handleSave = () => {
    if (username !== "" && username !== currentUser.displayName) {
      changeName();
    }

    if (newPassword !== "") {
      changePassword();
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Benutzerdaten ändern</ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label for="email">E-Mail</Label>
          <Input
            type="email"
            name="email"
            id="email"
            value={currentUser.email}
            disabled
          />
        </FormGroup>
        <FormGroup>
          <Label for="username">Vor und Nachname</Label>
          <Input
            placeholder={currentUser.displayName ? currentUser.displayName : ""}
            type="text"
            name="username"
            id="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            onKeyDown={handleKeyDown}
          />
        </FormGroup>
        <FormGroup>
          <Label for="password">Passwort ändern</Label>
          <Input
            type="password"
            name="password"
            id="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            onKeyDown={handleKeyDown}
          />
        </FormGroup>
        {newPassword && (
          <>
            <FormGroup>
              <Label for="confirmPassword">Passwort wiederholen</Label>
              <Input
                required={newPassword ? true : false}
                type="password"
                name="confirmPassword"
                id="confirmPassword"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label for="currentPassword">Aktuelles Passwort eingeben</Label>
              <Input
                required={newPassword ? true : false}
                type="password"
                name="currentPassword"
                id="currentPassword"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
                onKeyDown={handleKeyDown}
              />
            </FormGroup>
          </>
        )}

        {error && <Alert color="danger">{error}</Alert>}
        {success && <Alert color="success">{success}</Alert>}
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleSave}>
          Speichern
        </Button>
        <Button color="secondary" onClick={toggle}>
          Abbrechen
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default UserModal;
