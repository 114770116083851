import React from "react";
import { Container, Row, Col } from "reactstrap";

import logo from "../assets/img/logo.svg";

const Header = () => {
  return (
    <div className="header-container">
      <div className="header-overlay">
        <Container>
          <Row className="align-items-center">
            <Col xs={12} md={3}>
              <img src={logo} alt="Logo" className="logo" />
            </Col>
            <Col xs={12} md={9}>
              <div className="header-text">
                <h1 className="header-title">Freiwillige Feuerwehr Kirchen</h1>
                <h5 className="header-subtitle">- Fahrerkarten - </h5>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Header;
